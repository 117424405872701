import { createApp } from 'vue';
import { sync } from 'vuex-router-sync';
import { createDesignSystem, components } from '@infusionsoft/design-system';

import sentry from './analytics/sentry';
import { createFullStory } from '@keap-web/analytics-lib/fullstory';
import App from './App.vue';
import { createRouter } from './router';
import { createStore } from './store';
import { i18n } from './i18n/index';
import { createBus } from '@keap-web/shared-ui';
import { setupInterceptors } from './utils/interceptor.util';
import './styles/main.scss';

const app = createApp(App);
const store = createStore();
const router = createRouter(store);
const designSystem = createDesignSystem({
    components,
    useNewIllustrations: true,
    focusOnKeyboard: true,
    dropdownNext: true,
    guideNext: true,
    tooltipNext: true,
    popoverNext: true,
    onOverlayActive: () => { store.commit('SET_OVERLAY_ACTIVE', true); },
    onOverlayInactive: () => { store.commit('SET_OVERLAY_ACTIVE', false); },
});
const bus = createBus();

const fullstory = createFullStory({
    orgId: window.__KEAP__.VUE_APP_FULLSTORY_ORG,
    devMode: import.meta.env.DEV,
    enabled: import.meta.env.VUE_APP_THIRD_PARTY_LOGGING === 'enabled',
});

app.use(designSystem);
app.use(router);
app.use(store);
app.use(i18n);
app.use(bus);
app.use(fullstory);

app.provide('rainforest', {
    paymentSrc: window.__KEAP__.VUE_APP_RAINFOREST_PAYMENT_SCRIPT_URL,
    merchantSrc: window.__KEAP__.VUE_APP_RAINFOREST_MERCHANT_SCRIPT_URL,
});

app.config.globalProperties.$number = i18n.global.n;

if (process.env.NODE_ENV !== 'development') {
    sentry.init({ app, router });
}

setupInterceptors(store);
sync(store, router);

app.mount('#app');
